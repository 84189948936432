import { FC } from "react";
import { HStack, VStack } from "../../../shared/ui/Stack"
import { TextConstructor } from "../../../shared/ui/TextConstructor";
import { PhoneInput } from "../../../shared/ui/PhoneInput";
import { Button } from "../../../shared/ui/Button";

export interface RestoreFormProps {
  // Поля
  phone: string;
  // Коллбеки
  onChangePhone: (phoneNumber: string) => void;
  toLoginStep: () => void;
  onClickSendSmsCode: () => void;
  // Ошибки
  errorPhone: string;
}

export const RestoreForm: FC<RestoreFormProps> = (props) => {
  const {
    toLoginStep,
    errorPhone,
    onChangePhone,
    onClickSendSmsCode,
    phone,
  } = props;

  return (
    <VStack gap="24" align="stretch">
      <HStack align="stretch"  justify="between">
        <TextConstructor color="#000000" fz="24" lh="20">
          Восстановление пароля
        </TextConstructor>
        <button onClick={toLoginStep} className="resetButtonTagStyles">
          <TextConstructor color="#0D8E74" fz="18" lh="20">
            Вход
          </TextConstructor>
        </button>
      </HStack>

      <PhoneInput value={phone} onChange={onChangePhone} errorText={errorPhone} />

      <Button
        typeButton="ButtonSquare" onClick={onClickSendSmsCode}
      >
        Получить SMS-код
      </Button>
    </VStack>
  );
};
