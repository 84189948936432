import { CourseCard, CoursesList, mapCoursesWithAuthors } from "../../../entities/Course";
import { useCoursesQuery } from "../../../entities/Courses";
import { ID_POSITION_MAP } from '../../../shared/const/ID_POSITION_MAP'
import { sortElementsByIdPosition } from '../../../shared/utils/sortElementsByIdPosition'

const firstCourse = {
  "name": "Детская Психология",
  "description": "Узнаете, как восстановить доверие ребенка и вернуть гармонию в семью. Получите задания для укрепления отношений и создания эмоциональной связи. Научи справляться с конфликтами и избегать их в дальнейшем при общение с ребенком.",
  "active": true,
  "free": false,
  "intro_video": "",
  "product_id": 13,
  "landing_link": " https://info.digitalgeneration.ru/sale",
  "pay_link": " https://info.digitalgeneration.ru/sale",
  "pay_button_text": "",
  "force_open_lessons": true,
  "hex_code": "",
  "hit_sales": true,
  "available_from": null,
  "available_to": null,
  "available_in_catalog": true,
  "duration": "120",
  "info": "",
  "skills": "",
  "id": 59,
  "image": "https://storage.yandexcloud.net/ru.dg.learning/original/nRRX4VGBKAbxTzKyzGw2hr.png",
  "weight": 1,
  "printed_material": false,
  "rating": 5,
  "every_rate": {
    "1": 0,
    "2": 0,
    "3": 0,
    "4": 0,
    "5": 0
  },
  "learn_time": 101,
  "comment_count": 0,
  "lessons_complete": null,
  "percent_complete": null,
  "lesson_count": 10
}

export const AllCourses = () => {
  const { data } = useCoursesQuery();

  const courses = [...(data?.result || [])];
  // @ts-ignore
  courses.unshift(firstCourse);
  const sortedCourses = sortElementsByIdPosition(courses || [], ID_POSITION_MAP);

  return (
    <CoursesList>
      {mapCoursesWithAuthors(sortedCourses).map(({
        // @ts-ignore
        id, name, description, pay_button_text, author, landing_link, isAvailable, hex_code, free,
      }) => (
        <CourseCard
          id={String(id)}
          name={name}
          description={description}
          buttonText={pay_button_text}
          author={author}
          href={landing_link}
          isAvailable={isAvailable}
          backgroundColor={hex_code || ''}
          tags={[(free ? 'бесплатно' : '-30%'), 'Хит']}
        />
      ))}
    </CoursesList>
  );
};
