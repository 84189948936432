import picture from '../../../../../shared/assets/images/happyWoman.jpg';
import { FC } from "react";
import figureFive from '../../../../../shared/assets/images/figureStarPng.png';
import { CourseSlideTemplate } from "../CourseSlideTemplate/CourseSlideTemplate";

interface SlideFiveProps {
  device: 'desktop' | 'tablet' | 'mobile';
}

export const SlideFive: FC<SlideFiveProps> = ({ device }) => {
  return (
    <CourseSlideTemplate
      device={device}
      figureSrc={figureFive}
      href="https://info.digitalgeneration.ru/sale"
      picture={picture}
      subTitle="Курс Подростковая Психология"
      title="Смогла найти подход к сыну! Было сложно, но у меня получилось."
      backgroundColor="#D1D4FF"
      labelTitle="Екатерина"
      labelSubtitle="многодетная мама"
      labelBackgroundColor="#4E56D3"
			titleColor="#4E56D3"
    />
  )
};
