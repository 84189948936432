import { FC, useState } from "react";
import { Button } from "../../../shared/ui/Button";
import { Checkbox } from "../../../shared/ui/Checkbox";
import { Input } from "../../../shared/ui/Input";
import { PhoneInput } from "../../../shared/ui/PhoneInput";
import { HStack, VStack } from "../../../shared/ui/Stack";
import IconCloseEye from '../../../shared/assets/images/closeEye.svg'
import s from './RegisterForm.module.scss';
import { TextConstructor } from "../../../shared/ui/TextConstructor";

interface RegisterFormProps {
  // Поля
  email: string,
  phone: string,
  name: string;
  password: string;
  passwordAgain: string;
  isCheckPersonalDadaCheckbox: boolean;
  // Коллбеки
  onChangeEmailHandler: (e: React.FormEvent<HTMLInputElement>) => void;
  onChangePhoneNumber: (e: string) => void;
  onChangeNameHandler: (e: React.FormEvent<HTMLInputElement>) => void;
  onChangePasswordHandler: (e: React.FormEvent<HTMLInputElement>) => void;
  onChangePasswordAgainHandler: (e: React.FormEvent<HTMLInputElement>) => void;
  onChangeCheckboxHandler: () => void;
  onClickSendSMSHandler: () => void;
  onClickEnter: () => void;
  // Ошибки
  errorEmail: string;
  errorPhone: string;
  errorName: string;
  errorPassword: string;
  errorPasswordAgain: string;
  errorPersonalDadaCheckbox: string;
  // Остальное
  isShowErrors: boolean;
  isLoading: boolean;
}

export const RegisterForm: FC<RegisterFormProps> = (props) => {
  const {
  email,
  phone,
  name,
  password,
  passwordAgain,
  isCheckPersonalDadaCheckbox,
  onChangeEmailHandler,
  onChangePhoneNumber,
  onChangeNameHandler,
  onChangePasswordHandler,
  onChangePasswordAgainHandler,
  onChangeCheckboxHandler,
  onClickSendSMSHandler,
  onClickEnter,
  errorEmail,
  errorPhone,
  errorName,
  errorPassword,
  errorPasswordAgain,
  errorPersonalDadaCheckbox,
  isShowErrors,
  isLoading,
  } = props;

  const [inputType, setInputType] = useState<'password' | 'text'>('password');

  const onClickIconHandler = () => {
    setInputType((prev) => prev === 'password' ? 'text' : 'password');
  }

  const Icon = () => (
    <button className="resetButtonTagStyles" onClick={onClickIconHandler}>
      <img src={IconCloseEye} alt="*" />
    </button>
  );

  return (
    <VStack align="stretch" gap="24" max>
      <HStack justify="between">
        <TextConstructor fz="24" color="#000000" lh="20" >
          Регистрация
        </TextConstructor>
        <button onClick={onClickEnter} className="resetButtonTagStyles">
          <TextConstructor color="#0D8E74" fz="18" lh="20">
            Вход
          </TextConstructor>
        </button>
      </HStack>
      <Input
        onChange={onChangeEmailHandler}
        value={email} label="Почта"
        className={s.input}
        errorText={isShowErrors ? errorEmail : ''}
      />
      <PhoneInput
        onChange={onChangePhoneNumber}
        className={s.input}
        errorText={isShowErrors ? errorPhone : ''}
        value={phone}
      />
      <Input
        onChange={onChangeNameHandler}
        value={name} label="Имя"
        className={s.input}
        errorText={isShowErrors ? errorName : ''}
      />
      <Input
        onChange={onChangePasswordHandler}
        value={password}
        label="Пароль"
        className={s.input}
        rightNode={Icon()}
        type={inputType}
        errorText={isShowErrors ? errorPassword : ''}
      />
      <Input
        onChange={onChangePasswordAgainHandler}
        value={passwordAgain}
        label="Повторно введите пароль"
        className={s.input}
        rightNode={Icon()}
        type={inputType}
        errorText={isShowErrors ? errorPasswordAgain : ''}
      />
      <HStack align="start" gap="8">
        <Checkbox isChecked={isCheckPersonalDadaCheckbox} onChange={onChangeCheckboxHandler} isError={isShowErrors && !!errorPersonalDadaCheckbox} />
        <div>
          Нажимая на кнопку я соглашаюсь на обработку{' '}
          <a href="https://disk.yandex.ru/i/oRaUUlm_Tj4lgQ" target="_blank" rel="noreferrer">персональных данных</a>
        </div>
      </HStack>
      <Button
        typeButton="ButtonSquare"
        onClick={onClickSendSMSHandler}
        isLoading={isLoading}
      >
        Получить SMS-код
      </Button>
    </VStack>
  );
};
