import { FC } from "react";
import { TextConstructor } from "../../../../../shared/ui/TextConstructor";
import { VStack } from "../../../../../shared/ui/Stack";
import { NewText } from "../../../../../shared/ui/NewTypography/NewTypography";
import classNames from "classnames";
import s from './CourseSlideTemplate.module.scss';

interface CourseSlideTemplateProps {
  device: 'desktop' | 'tablet' | 'mobile';
  title: string;
  subTitle: string;
  href: string;
  picture: string;
  figureSrc: string;
  backgroundColor: string;
  labelTitle: string;
  labelSubtitle: string;
  labelBackgroundColor: string;
  titleColor?: string;
}

export const CourseSlideTemplate: FC<CourseSlideTemplateProps> = ({
  device, figureSrc, href, picture, subTitle, title, backgroundColor, labelBackgroundColor, labelSubtitle, labelTitle, titleColor,
}) => {
  const Content = () => {
    if (device !== 'desktop') {
      return (
        <>
          <VStack gap="10">
            <TextConstructor fz="16" fw="500" lh="20" font="Akzidenz-Grotesk" {...(titleColor ? { color: titleColor } : {})}>
              {title}
            </TextConstructor>
            <a href={href} target="_blank">
              <TextConstructor fz="16" fw="500" lh="20" textDecoration="underline">
                👉🏽 {subTitle} 👈🏽
              </TextConstructor>
            </a>
          </VStack>

          <img src={picture} alt="*" className={s.image} />

          <VStack className={s.card} style={{ backgroundColor: labelBackgroundColor }}>
            <TextConstructor fz="16" fw="600" lh="20" color="#FFFFFF">
              Мария
            </TextConstructor>
            <TextConstructor fz="16" fw="400" lh="20" color="#FFFFFF">
              Директор по развитию
            </TextConstructor>
          </VStack>

          <img src={figureSrc} alt="*" className={s.figure} />
        </>
      );
    }

    return (
      <>
        <VStack gap="10">
          <NewText name="H5" {...(titleColor ? { styleColor: titleColor } : {})}>
            {title}
          </NewText>
          <a href={href} target="_blank">
            <NewText name="Bt" textDecoration="underline">
              👉🏽 {subTitle} 👈🏽
            </NewText>
          </a>
        </VStack>

        <img src={picture} alt="*" className={s.image} />

        <VStack className={s.card} style={{ backgroundColor: labelBackgroundColor }}>
          <NewText name="H7" color="white">
            {labelTitle}
          </NewText>
          <NewText name="Text"  color="white">
            {labelSubtitle}
          </NewText>
        </VStack>

        <img src={figureSrc} alt="*" className={s.figure} />
      </>
    );
  }

  return (
    <div className={classNames(s.slide, device)} style={{ backgroundColor }}>
      {Content()}
    </div>
  );
};
