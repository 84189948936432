import { CoursesElementRequestResultType } from "../../Courses/types/couresTypes";
import { CoursesElementRequestResultTypeWithVisualElements, VisualElements } from "../types/courseTypes";
// speakers
import skeaker1  from './img/speakers/img.png';
import skeaker2  from './img/speakers/img (1).png';
import skeaker3  from './img/speakers/img (2).png';
import skeaker4  from './img/speakers/img (3).png';
import skeaker5  from './img/speakers/img (4).png';
import skeaker6  from './img/speakers/img (5).png';
import skeaker7  from './img/speakers/img (6).png';
import skeaker8  from './img/speakers/img (7).png';
// figures
import money1_1 from './img/figures/money.png';
import money1_2 from './img/figures/money2.png';
import money2_1 from './img/figures/money png (1).png';
import money2_2 from './img/figures/money png (2).png';
import money3_1 from './img/figures/money png (3).png';
import money3_2 from './img/figures/money png2 (1).png';
import money4_1 from './img/figures/money png (4).png';
import money4_2 from './img/figures/money png2 (2).png';
import money5_1 from './img/figures/money png (5).png';
import money5_2 from './img/figures/money png2 (3).png';
import money6_1 from './img/figures/money png (6).png';
import money6_2 from './img/figures/money png2 (4).png';
import money7_1 from './img/figures/money png (7).png';
import money7_2 from './img/figures/money png2 (5).png';

interface Mook extends VisualElements {
  courseId: number;
}

const mooks: Mook[] = [
  {
    courseId: 34,
    speakerPhoto: skeaker1,
    leftImage: money1_1,
    rightImage: money1_2,
  },
  {
    courseId: 45,
    speakerPhoto: skeaker2,
    leftImage: money2_1,
    rightImage: money2_2,
  },
  {
    courseId: 41,
    speakerPhoto: skeaker3,
    leftImage: money3_1,
    rightImage: money3_2,
  },
  {
    courseId: 43,
    speakerPhoto: skeaker4,
    leftImage: money4_1,
    rightImage: money4_2,
  },
  {
    courseId: 999,
    speakerPhoto: skeaker5,
    leftImage: money5_1,
    rightImage: money5_2,
  },
  {
    courseId: 52,
    speakerPhoto: skeaker6,
    leftImage: money6_1,
    rightImage: money6_2,
  },
  {
    courseId: 42,
    speakerPhoto: skeaker7,
    leftImage: money7_1,
    rightImage: money7_2,
  },
  {
    courseId: 59,
    speakerPhoto: skeaker8,
    leftImage: money6_1,
    rightImage: money6_2,
  },
];

export const mapCoursesWithVisualElements = (courses: CoursesElementRequestResultType[]): CoursesElementRequestResultTypeWithVisualElements[] => {
  return (
    courses.map((course) => {
      const mook = mooks.find(({ courseId }) => courseId === course.id);

      if (!mook) {
        return course;
      }

      return {
        ...course,
        visualElements: mook,
      };
    })
  );
};
