import { CSSProperties, FC, ReactNode } from "react";
import s from './NewTypography.module.scss';
import classNames from "classnames";

interface NewTypographyProps {
  name:
    'Text'
    | 'Bt'
    | 'question'
    | 'tag'
    | 'H2'
    | 'H3'
    | 'H4'
    | 'H5'
    | 'H6'
    | 'H7'
    | 'Title_2_Regular'
    | 'Title_3_Regular'
    | 'Chapter_MediumExtended'
    | 'Headline_LightExtended'
    | 'Text_1_BoldExtended'
  children: ReactNode;
  As?: 'span' | 'div';
  className?: string;
  color?:
    'white'
    | 'black'
    | 'grey1'
    | 'grey2'
    | 'neonGreen'
    | 'blue'
    | 'greenMain',
  font?: '',
  textTransform?: CSSProperties['textTransform'];
  textDecoration?: CSSProperties['textDecoration'];
  textAlign?: CSSProperties['textAlign'];
  styleColor?: CSSProperties['color'];
}

const NewTypography: FC<NewTypographyProps> = (props) => {
  const {
    children,
    name,
    As = 'span',
    color = 'black',
    className,
    textTransform,
    textDecoration,
    textAlign,
    styleColor,
  } = props;

  return (
    <As className={classNames(s[name], color, className)} style={{ color: styleColor, textTransform, textDecoration, textAlign }}>
      {children}
    </As>
  )
};

interface NewTextProps extends NewTypographyProps {};

export const NewText: FC<NewTextProps> = (props) => {
  return (
    <NewTypography {...props} />
  );
};
