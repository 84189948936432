import { CoursesElementRequestResultType } from "../../Courses/types/couresTypes";
import { CoursesElementRequestResultTypeWithAuthors } from "../types/courseTypes";
import photo1 from './img/avatarsOfSpeakers/Ellipse.png';
import photo2 from './img/avatarsOfSpeakers/photo.png';
import photo3 from './img/avatarsOfSpeakers/photo (1).png';
import photo4 from './img/avatarsOfSpeakers/photo (2).png';
import photo5 from './img/avatarsOfSpeakers/photo (3).png';
import photo6 from './img/avatarsOfSpeakers/photo (4).png';
import photo7 from './img/avatarsOfSpeakers/photo (5).png';
import photo8 from './img/avatarsOfSpeakers/photo (6).jpg';

const mooks = [
  {
    courseId: 34,
    name: 'Никита Маланьин',
    description: 'Управляющий партнер в агентстве Arch Capital',
    photo: photo1,
  },
  {
    courseId: 45,
    name: 'Денис Ивлев',
    description: 'Финансовый консультант',
    photo: photo2,
  },
  {
    courseId: 41,
    name: 'Дарья Романенко',
    description: 'Консультант по созданию и анализу SMM-стратегии',
    photo: photo3,
  },
  {
    courseId: 43,
    name: 'Павел Никифоров',
    description: 'Руководитель продуктовых и бренд-коммуникаций Tele2',
    photo: photo4,
  },
  {
    courseId: 999,
    name: 'Дарья Голицына',
    description: 'PR директор, продюсер',
    photo: photo5,
  },
  {
    courseId: 52,
    name: 'Анастасия Валова',
    description: 'Собственник агентства AYA Group Consulting',
    photo: photo6,
  },
  {
    courseId: 42,
    name: 'Анна Егорова',
    description: 'PR консультант',
    photo: photo7,
  },
  {
    courseId: 59,
    name: 'Марина Старковская',
    description: 'Психолог с 10 летним стажем, член Международной ассоциации психологов',
    photo: photo8,
  },
];

export const mapCoursesWithAuthors = (courses: CoursesElementRequestResultType[]): CoursesElementRequestResultTypeWithAuthors[] => {
  return (
    courses.map((course) => {
      const mook = mooks.find(({ courseId }) => courseId === course.id);

      if (!mook) {
        return course;
      }

      return {
        ...course,
        author: mook,
      };
    })
  );
};
