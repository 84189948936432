import { Link } from "react-router-dom";
import { CourseCard, CoursesList, mapCoursesWithVisualElements, mapCoursesWithAuthors } from "../../../entities/Course";
import { useCoursesQuery } from "../../../entities/Courses";
import { NewText } from "../../../shared/ui/NewTypography/NewTypography";
import { HStack, VStack } from "../../../shared/ui/Stack";
import { TextConstructor } from "../../../shared/ui/TextConstructor";
import { toCourses } from "../../../shared/config/routeConfig/routeConfig";
import { useMyCoursesQuery } from "../../MyCoursesList/api/myCoursesListApi";
import { sortElementsByIdPosition } from '../../../shared/utils/sortElementsByIdPosition';
import { ID_POSITION_MAP } from '../../../shared/const/ID_POSITION_MAP';

const firstCourse = {
  "name": "Детская Психология",
  "description": "Узнаете, как восстановить доверие ребенка и вернуть гармонию в семью. Получите задания для укрепления отношений и создания эмоциональной связи. Научи справляться с конфликтами и избегать их в дальнейшем при общение с ребенком.",
  "active": true,
  "free": false,
  "intro_video": "",
  "product_id": 13,
  "landing_link": " https://info.digitalgeneration.ru/sale",
  "pay_link": " https://info.digitalgeneration.ru/sale",
  "pay_button_text": "",
  "force_open_lessons": true,
  "hex_code": "",
  "hit_sales": true,
  "available_from": null,
  "available_to": null,
  "available_in_catalog": true,
  "duration": "120",
  "info": "",
  "skills": "",
  "id": 59,
  "image": "https://storage.yandexcloud.net/ru.dg.learning/original/nRRX4VGBKAbxTzKyzGw2hr.png",
  "weight": 1,
  "printed_material": false,
  "rating": 5,
  "every_rate": {
    "1": 0,
    "2": 0,
    "3": 0,
    "4": 0,
    "5": 0
  },
  "learn_time": 101,
  "comment_count": 0,
  "lessons_complete": null,
  "percent_complete": null,
  "lesson_count": 10
}

export const Courses = () => {
  const { data } = useCoursesQuery();
  const { data: dataMyCourses } = useMyCoursesQuery();

  const myCourses = dataMyCourses?.result;

  const numberCourses = data?.result.length;

  const sortedCourses = sortElementsByIdPosition(data?.result || [], ID_POSITION_MAP);
  sortedCourses.unshift(firstCourse);

  const courses = [...(sortedCourses || [])].splice(0, 4);
  
  const coursesWithVisualElements = mapCoursesWithVisualElements(courses);
  const coursesWithAuthors = mapCoursesWithAuthors(coursesWithVisualElements);
  const coursesWithAvaible = coursesWithAuthors.map((course) => ({ ...course, isAvailable: !!myCourses?.some(({ id }) => id === course.id) }));

  return (
    <VStack gap="40" max>
      <HStack align="end" justify="between" max>
        <HStack gap="10" align="start">
          <NewText name="H2">
            Курсы
          </NewText>
          <TextConstructor
            fw="700"
            fz="30"
            lh="32"
            color="#74B500"
            font="Akzidenz-Grotesk"
          >
            {numberCourses}
          </TextConstructor>
        </HStack>

        <Link to={toCourses()}>
          <NewText name="Bt" color="greenMain">
            Все курсы
          </NewText>
        </Link>
      </HStack>

      <CoursesList>
        {coursesWithAvaible.map(({
          // @ts-ignore
          id, name, description, pay_button_text, visualElements, author, landing_link, isAvailable,
        }, index) => (
          <CourseCard
            id={String(id)}
            name={name}
            description={description}
            buttonText={pay_button_text}
            author={author}
            visualElements={{
              ...visualElements,
              variantFigureBackground: index + 1,
            }}
            href={landing_link}
            isAvailable={isAvailable}
            tags={['-30%', 'Хит']}
          />
        ))}
      </CoursesList>
    </VStack>
  );
};
